<template>
  <div class="textBox">{{ Text }}</div>

  <!-- <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1Box max-w-1400">
        <img :src="importedImg1" alt="" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2Box max-w-1400">
        <img :src="importedImg2" alt="" class="imgItem2" />
      </div>
    </div>
    <div class="imgBox3 min-w-1400">
      <div class="img3Box max-w-1400">
        <img :src="importedImg3" alt="" class="imgItem3" />
      </div>
    </div>
    <div class="imgBox4 min-w-1400">
      <div class="img4Box max-w-1400">
        <img :src="importedImg4" alt="" class="imgItem4" />
      </div>
    </div>
    <div class="imgBox5 min-w-1400">
      <div class="img5Box max-w-1400">
        <img :src="importedImg5" alt="" class="imgItem5" />
      </div>
    </div>
  </div> -->
</template>

<script>
import "./index.css";
import importedImg1 from "@/page/bengreenvietnamcompanylimited/vn/components/img/1_1.png";
import importedImg2 from "@/page/bengreenvietnamcompanylimited/vn/components/img/1_2.png";
import importedImg3 from "@/page/bengreenvietnamcompanylimited/vn/components/img/1_3.png";
import importedImg4 from "@/page/bengreenvietnamcompanylimited/vn/components/img/1_4.png";
import importedImg5 from "@/page/bengreenvietnamcompanylimited/vn/components/img/1_5.png";
// import importedImg6 from "@/page/bengreenvietnamcompanylimited/vn/components/img/1_6.png";
// import importedImg7 from "@/page/bengreenvietnamcompanylimited/vn/components/img/1_7.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      importedImg2: importedImg2,
      importedImg3: importedImg3,
      importedImg4: importedImg4,
      importedImg5: importedImg5,
      // importedImg6: importedImg6,
      // importedImg7: importedImg7,
      altText: "图片",
      Text: `นโยบายความเป็นส่วนตัวของ Q Money พัฒนาโดย GOMATRIX COMPANY LIMITED จะเเจ้งให้ผู้ใช้ทราบว่าเรารวบรวมและใช้ข้อมูลผู้ใช้อย่างไร Q Money ซึ่งเป็นผลิตภัณฑ์ของ GOMATRIX COMPANY LIMITED จะไม่เปิดเผยข้อมูลส่วนบุคคลของผู้ใช้ และจะใช้เฉพาะข้อมูลผู้ใช้เพื่อการวิเคราะห์ที่เกี่ยวข้องกับธุรกิจและการประเมินเครดิตสินเชื่อเท่านั้น

ข้อมูลผลิตภัณฑ์

วงเงินกู้สูงสุด 40,000 บาท

ระยะเวลากู้ยืม: 93 วัน (ขั้นต่ำ - รวมระยะสั้น) - 365 วัน (สูงสุด - รวมระยะยาว)

อัตราร้อยละต่อปี (APR): สูงถึง 14%/ปี

ค่าธรรมเนียมการทำธุรกรรม: 0

เช่น วงเงินกู้ 40,000 บาท อัตราดอกเบี้ย 14% ต้องใช้เวลาชำระดอกเบี้ย 120 วัน

รวมดอกเบี้ยเป็น 40,000 บาท * 0.14/365 * 120 = 1841 บาท

ดังนั้นดอกเบี้ยรายเดือนคือ 40,000 บาท * 0.14/365 * 30 = 460 บาท

จำนวนเงินที่ชำระ = 40,000 + ดอกเบี้ย = 40,000 + 1841 = 41,841 บาท

ยอดรายเดือน = 41,841/120 * 30 = 10,460 บาท

1. ข้อมูลที่รวบรวมโดย Q Money

เมื่อเราให้บริการ เราอาจรวบรวม จัดเก็บ และใช้ข้อมูลต่อไปนี้เกี่ยวกับคุณและคำขออนุญาตของคุณ หากคุณไม่ให้ข้อมูลที่เกี่ยวข้อง คุณอาจไม่สามารถลงทะเบียนเป็นผู้ใช้หรือใช้บริการบางอย่างที่เรามีให้ หรือคุณอาจไม่สามารถบรรลุผลลัพธ์ที่คาดหวังของบริการได้ บริการของเราเป็นไปด้วยความสมัครใจและคุณไม่จำเป็นต้องให้ข้อมูลดังกล่าว เว้นแต่คุณจะเลือกใช้บริการ Q Money ของเรา

ข้อมูลส่วนบุคคล: รวมถึงชื่อ หมายเลขโทรศัพท์มือถือ หมายเลขประจำตัวประชาชน ข้อมูลติดต่อที่เกี่ยวข้อง รูปภาพ ที่อยู่บริษัท หมายเลขบัตรธนาคาร หนังสือรับรองการทำงาน ฯลฯ

ข้อมูลที่เกี่ยวข้องกับอุปกรณ์ของคุณ เช่น ที่อยู่ IP, เวอร์ชัน Android, ข้อมูลอุปกรณ์เครือข่าย, หมายเลขซีเรียลของอุปกรณ์, รายการการติดตั้งแอปพลิเคชัน, ข้อมูลสมุดที่อยู่, SSID เป็นต้น

ข้อมูลตำแหน่ง (ตำแหน่งทางภูมิศาสตร์): หลังจากที่คุณให้อนุญาต เราจะเข้าถึงและดึงข้อมูลตำแหน่งโดยประมาณของคุณ ซึ่งส่วนใหญ่เป็นลองจิจูดและละติจูด จากข้อมูลตำแหน่งของคุณ เราจะมอบผลิตภัณฑ์และบริการที่ตรงกับความต้องการของคุณมากขึ้น ข้อมูลตำแหน่งใช้เพื่อประเมินความน่าเชื่อถือของคุณ โปรดทราบว่าหากคุณปิดใช้งานการอนุญาตนี้ คุณจะเพิกถอนการอนุญาตนี้ในนามของคุณ เราจะไม่รวบรวมและใช้ข้อมูลส่วนบุคคลของคุณอีกต่อไป และจะไม่สามารถให้บริการที่เกี่ยวข้องแก่คุณได้ อย่างไรก็ตาม การตัดสินใจปิดการใช้งานการอนุญาตของคุณจะไม่ส่งผลกระทบต่อข้อมูลที่รวบรวมและใช้ก่อนหน้านี้ภายใต้ความยินยอมของคุณ

ข้อมูลธนาคาร: รวมถึงชื่อ หมายเลขบัญชี ฯลฯ วัตถุประสงค์ของการรวบรวมข้อมูลนี้คือเพื่อปรับปรุงบริการที่เรามอบให้กับคุณ ข้อมูลที่ให้ไว้ขึ้นอยู่กับวิธีที่คุณใช้ข้อมูลดังกล่าวเพื่อเข้าร่วมบริการของเรา การใช้บริการของเราเป็นไปตามความสมัครใจโดยสิ้นเชิง และคุณไม่จำเป็นต้องให้ข้อมูลดังกล่าว เว้นแต่คุณจะเลือกใช้บริการ Q Money ของเรา

ข้อมูลการสมัครและธุรกรรมหมายถึงการวิเคราะห์ข้อมูลธุรกรรมของเราจากการสมัคร สินเชื่อ และแบบฟอร์มอื่น ๆ ที่คุณกรอกใน Q Money และสร้างฐานข้อมูลเครดิตผู้ใช้

ข้อมูลพฤติกรรมหมายถึงกิจกรรมที่คุณใช้บน Q Money เช่น การลงทะเบียน การเข้าสู่ระบบ แอปพลิเคชัน และกิจกรรมอื่น ๆ ที่เกี่ยวข้อง ข้อมูลพฤติกรรมการคลิก หากจำเป็น เราอาจเข้าถึงบริการของแพลตฟอร์มสถิติข้อมูลของบุคคลที่สาม

ข้อความ: เราอาจขอเข้าถึงข้อความของคุณ (SMS) เพื่อยืนยันตัวตนและสถานะทางการเงินของคุณเมื่อคุณสมัครสินเชื่อ ด้วยการวิเคราะห์ข้อความตัวอักษรเฉพาะบนอุปกรณ์ของคุณ เราจะประเมินความเสี่ยงด้านเครดิตได้แม่นยำยิ่งขึ้น และป้องกันการฉ้อโกงที่อาจเกิดขึ้น โปรดมั่นใจได้ว่าเราจะเข้าถึงเฉพาะเนื้อหาข้อความที่เกี่ยวข้องกับการสมัครสินเชื่อของคุณเท่านั้น ข้อมูลทั้งหมดจะถูกเข้ารหัสและส่งอย่างปลอดภัย และจะไม่ถูกแชร์กับบุคคลที่สามโดยไม่ได้รับความยินยอมจากคุณ

บันทึกการโทร: ในระหว่างกระบวนการสมัครสินเชื่อของคุณ เราอาจจำเป็นต้องเข้าถึงบันทึกการโทรของคุณเพื่อยืนยันความถี่ ระยะเวลา และการรับสาย สิ่งนี้ช่วยให้เราตรวจสอบข้อมูลติดต่อและตัวตนของคุณได้ดีขึ้น จึงช่วยเพิ่มประสิทธิภาพประสบการณ์ผู้ใช้และความปลอดภัยของบัญชีของคุณ เราจะดึงข้อมูลที่เกี่ยวข้องเพื่อการประเมินเครดิตเท่านั้น และข้อมูลใด ๆ ที่ได้รับจะได้รับการคุ้มครองอย่างเข้มงวดและจะไม่นำไปใช้เพื่อวัตถุประสงค์อื่นหรือแบ่งปันกับหน่วยงานภายนอก

ปฏิทิน: เราอาจขอเข้าถึงปฏิทินของคุณเพื่อช่วยคุณจัดการวันที่ชำระคืนเงินกู้และการแจ้งเตือน เมื่อได้รับสิทธิ์ในปฏิทิน คุณสามารถรับการแจ้งเตือนเกี่ยวกับเรื่องสำคัญ เช่น วันที่ชำระคืน การหมดอายุของสัญญา ฯลฯ เพื่อหลีกเลี่ยงสถานการณ์ที่ค้างชำระ เราไม่อ่านกิจกรรมส่วนตัวอื่น ๆ ในปฏิทินของคุณและการเข้าถึงทั้งหมดถูกจำกัดไว้เพื่อช่วยให้คุณจัดการการแจ้งเตือนที่เกี่ยวข้องกับสินเชื่อ

กล้อง: เมื่อได้รับความยินยอมจากคุณ การอนุญาตนี้จะช่วยให้คุณสามารถอัปโหลดรูปภาพหรือไฟล์เพื่อยืนยันว่าคุณเป็นผู้ดำเนินการ ไม่ใช่บุคคลอื่น

คุณสามารถดูสถานะการอนุญาตได้ในการตั้งค่าของอุปกรณ์มือถือของคุณและตัดสินใจเปิดหรือปิดการใช้งาน เมื่อคุณเปิดใช้งานการอนุญาตเหล่านี้ คุณยังอนุญาตให้เรารวบรวมและใช้ข้อมูลส่วนบุคคลเพื่อทำหน้าที่ข้างต้น หากคุณปิดใช้งานการอนุญาตนี้ เราจะไม่รวบรวมและใช้ข้อมูลส่วนบุคคลของคุณอีกต่อไป และเราจะไม่สามารถให้ หน้าที่ข้างต้นตามการอนุญาตนี้ การยกเลิกความยินยอมของคุณจะไม่ส่งผลกระทบต่อการประมวลผลข้อมูลส่วนบุคคลก่อนหน้านี้ตามการอนุญาตของคุณ

2. ข้อมูลของคุณจัดทำโดยบุคคลที่สามที่ร่วมมือกัน

• หากจำเป็น เราจะติดต่อบุคคลที่สามของคุณ (รวมถึงแต่ไม่จำกัดเพียงหน่วยงานสินเชื่อแห่งชาติทางกฎหมาย แพลตฟอร์มบริการข้อมูลทางกฎหมาย) เพื่อสอบถามเกี่ยวกับข้อมูลประจำตัว งาน และข้อมูลรายงานเครดิตอื่นๆ ของคุณ ข้อมูล ครัวเรือนและรายได้ ข้อมูลบริการประกันสังคม ข้อมูลโปรไฟล์หนี้และเครดิต

3. Q Money จัดการข้อมูลส่วนบุคคลของคุณอย่างไร?

ข้อมูลที่เรารวบรวมจะถูกจัดเก็บและจัดการเพื่อวัตถุประสงค์ดังต่อไปนี้: เพื่อตรวจสอบข้อมูลที่คุณให้ไว้กับข้อมูลจริง โปรดตรวจสอบให้แน่ใจว่าไม่มีใครได้รับอนุญาตให้ใช้ข้อมูลประจำตัวของคุณ

กำหนดความสามารถของคุณในการรับเงินกู้ภายใต้เงื่อนไขปัจจุบัน

ยืนยันว่าข้อมูลที่คุณให้ในระหว่างขั้นตอนการสมัครสินเชื่อนั้นถูกต้อง

เรียนรู้วิธีที่คุณเข้าถึงและใช้บริการของเราเพื่อเพิ่มประสิทธิภาพและปรับปรุงกระบวนการทางธุรกิจและการออกแบบแอปพลิเคชันของเรา

ช่วยให้เราเข้าใจการตั้งค่ากิจกรรมและพฤติกรรมของผู้ใช้ และช่วยให้เราให้บริการสินเชื่อที่แม่นยำยิ่งขึ้น

ช่วยเราให้แน่ใจว่าการพัฒนาการดำเนินการให้สินเชื่อและการติดตามหนี้ของเราเป็นไปอย่างเป็นระเบียบ

4. Q Money ปกป้องข้อมูลส่วนบุคคลของผู้ใช้อย่างไร?

Q Money มุ่งมั่นที่จะใช้เทคโนโลยีล่าสุดเพื่อปกป้องข้อมูลของคุณ เราจะปฏิบัติตามข้อกำหนดที่เกี่ยวข้องเพื่อให้แน่ใจว่าข้อมูลของคุณจะถูกเก็บเป็นความลับและดูแลรักษา และการเข้าถึงถูกจำกัดเพื่อปกป้องคุณจากการฉ้อโกงทุกรูปแบบ คุณมีหน้าที่รับผิดชอบในการปกป้องรหัสการเข้าถึงบัญชีของคุณ ID ผู้ใช้และรหัสผ่านเพื่อให้แน่ใจว่าผู้ใช้รายอื่นไม่สามารถเข้าถึงบัญชีของคุณ

เกี่ยวกับข้อมูลของคุณ Q Money ไม่สามารถรับประกันความปลอดภัยของข้อมูลที่ส่งผ่านแอปพลิเคชันของเรา เมื่อเราได้รับข้อมูลของคุณ เราจะใช้คุณสมบัติและขั้นตอนด้านความปลอดภัยเพื่อป้องกันการเข้าถึงโดยไม่ได้รับอนุญาต

เกี่ยวกับข้อมูลของคุณ Q Money ไม่สามารถรับประกันความปลอดภัยของข้อมูลที่ส่งผ่านแอปพลิเคชันของเรา เมื่อเราได้รับข้อมูลของคุณ เราจะใช้คุณสมบัติและขั้นตอนด้านความปลอดภัยเพื่อป้องกันการเข้าถึงโดยไม่ได้รับอนุญาต

Q Money ปฏิบัติตามและปฏิบัติตามกฎหมายและข้อบังคับการคุ้มครองข้อมูลที่บังคับใช้ในประเทศไทยอย่างเต็มที่

5. สิทธิของผู้ใช้

เราให้ความสำคัญกับสิทธิ์ของคุณในการปกป้องข้อมูลส่วนบุคคลและมุ่งมั่นที่จะปกป้องสิทธิ์ของคุณตามกฎหมายและข้อบังคับที่เกี่ยวข้อง คุณสามารถใช้สิทธิ์ต่อไปนี้ในการจัดการและควบคุมข้อมูลส่วนบุคคลของคุณ:

สิทธิในการเข้าถึง แก้ไข และลบข้อมูลส่วนบุคคล

สิทธิ์ในการเข้าถึง: คุณมีสิทธิ์เข้าถึงข้อมูลส่วนบุคคลที่เรารวบรวมและจัดเก็บเกี่ยวกับคุณ หากต้องการเข้าถึงข้อมูลของคุณ คุณสามารถดูข้อมูลส่วนบุคคลของคุณผ่านทางหน้าศูนย์ส่วนบุคคลในแอปพลิเคชัน หากคุณต้องการข้อมูลโดยละเอียดเพิ่มเติม คุณสามารถติดต่อเราผ่านฟังก์ชัน "ช่วยเหลือและสนับสนุน" ภายในแอปพลิเคชัน หรือส่งอีเมลไปที่ finance@gomatrixcompany.com

สิทธิ์ในการแก้ไข: หากคุณพบว่าข้อมูลส่วนบุคคลที่เราจัดเก็บไม่ถูกต้องหรือไม่สมบูรณ์ คุณมีสิทธิ์ขอให้เราแก้ไขให้ถูกต้อง คุณสามารถอัปเดตข้อมูลส่วนบุคคลของคุณ เช่น หมายเลขติดต่อ ที่อยู่ หรือข้อมูลอื่น ๆ ผ่านทาง "ศูนย์ส่วนบุคคล" ในแอปพลิเคชัน หากคุณต้องการเปลี่ยนแปลงข้อมูลที่ซับซ้อนมากขึ้น โปรดติดต่อทีมบริการลูกค้า

สิทธิ์ในการลบ: คุณมีสิทธิ์ขอลบข้อมูลส่วนบุคคลของคุณภายใต้สถานการณ์บางอย่าง ตัวอย่างเช่น คุณสามารถขอให้เราลบข้อมูลส่วนบุคคลที่เกี่ยวข้องเมื่อบรรลุวัตถุประสงค์ของการประมวลผลข้อมูล หรือคุณเพิกถอนความยินยอมของคุณ คุณสามารถส่งคำขอลบข้อมูลผ่านหน้าการตั้งค่าบัญชีในแอปพลิเคชันหรือโดยติดต่อทีมบริการลูกค้าของเรา เราจะตรวจสอบคำขอของคุณเมื่อได้รับและตอบกลับภายในระยะเวลาที่เหมาะสม

การเพิกถอนความยินยอมและการยกเลิกบัญชี

สิทธิ์ในการเพิกถอนความยินยอม: หากคุณไม่ต้องการให้เราประมวลผลข้อมูลส่วนบุคคลของคุณอีกต่อไป คุณสามารถเพิกถอนความยินยอมของคุณได้ตลอดเวลา การเพิกถอนความยินยอมจะไม่ส่งผลกระทบต่อความถูกต้องตามกฎหมายของการประมวลผลข้อมูลที่เสร็จสมบูรณ์ก่อนการเพิกถอน ส่งคำขอเพิกถอนความยินยอมโดยส่งอีเมลไปที่ finance@gomatrixcompany.com เมื่อการดำเนินการถอนเสร็จสิ้น เราจะหยุดการประมวลผลข้อมูลส่วนบุคคลของคุณ เว้นแต่กฎหมายจะกำหนดไว้เป็นอย่างอื่น

สิทธิ์ในการยกเลิกบัญชี: หากคุณไม่ต้องการใช้บริการของเราอีกต่อไป คุณสามารถสมัครเพื่อยกเลิกบัญชีของคุณได้ หลังจากที่บัญชีถูกยกเลิก เราจะหยุดประมวลผลข้อมูลส่วนบุคคลทั้งหมดของคุณ และลบข้อมูลทั้งหมดของคุณตามที่กฎหมายอนุญาต โปรดทราบว่าในบางกรณี เราอาจเก็บข้อมูลบางส่วนไว้เนื่องจากข้อกำหนดทางกฎหมาย หากต้องการยกเลิกบัญชีของคุณ โปรดไปที่หน้าในแอป เลือกตัวเลือก "ยกเลิกบัญชี" และปฏิบัติตามคำแนะนำเพื่อดำเนินการให้เสร็จสิ้น หรือติดต่อทีมบริการลูกค้าโดยตรงเพื่อขอความช่วยเหลือ

วิธีใช้สิทธิเหล่านี้

คุณสามารถใช้สิทธิ์ข้างต้นได้ด้วยวิธีต่อไปนี้:

ไปที่หน้าศูนย์ส่วนบุคคลในแอปและปฏิบัติตามคำแนะนำที่ให้ไว้

ส่งคำขอไปยังทีมบริการลูกค้าของเราโดยส่งอีเมลไปที่ finance@gomatrixcompany.com

6. ข้อมูลผู้ใช้ที่ Q Money อาจแบ่งปันกับบุคคลที่สาม

Q Money จะเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้ของเราให้กับผู้ให้บริการ ผู้ยืม และสมาชิกในทีมที่ช่วยเราดำเนินการบางอย่าง รวมถึงบริการ การชำระเงิน ขั้นตอนป้องกันการฉ้อโกง Q Money จะแบ่งปันข้อมูลข้อมูลผู้ใช้ทางกฎหมายและศาลด้วย ให้ข้อมูลแก่บุคคลที่สามเพื่อป้องกันการสูญเสียทางการเงิน รายงานกิจกรรมที่ต้องสงสัยที่ผิดกฎหมาย หรือตรวจสอบการละเมิด

หากหนี้ของคุณค้างชำระอย่างร้ายแรงหรือคุณเสียชีวิตกะทันหัน คุณจะไม่สามารถชำระคืนเงินต้นและดอกเบี้ยเงินกู้ได้ เราจะเปิดเผยข้อมูลผู้ใช้ ข้อมูลสินเชื่อ และข้อมูลการกระทำผิดต่อสาธารณะหรือองค์กรพันธมิตรตามขอบเขตที่กฎหมายและข้อบังคับอนุญาต

เราจะเปิดเผยข้อมูลโปรไฟล์ของคุณ ข้อมูลสินเชื่อ และข้อมูลที่ค้างชำระเมื่อคุณฝ่าฝืนสัญญาหรือดำเนินการที่ส่งผลกระทบต่อกิจกรรมของ Q Money และส่งผลเสียต่อผู้ใช้รายอื่นและเริ่มต้นการดำเนินคดี

เงินกู้ของคุณค้างชำระอย่างจริงจังหรือคุณเสียชีวิตกะทันหันและไม่มีการชำระคืนเงินต้นและดอกเบี้ยของเงินกู้ เมื่อจำเป็น เจ้าหนี้จะมอบหมายให้ผู้รวบรวมให้ข้อมูลผู้ใช้และข้อมูลที่พ้นกำหนดชำระแก่ผู้รับที่ได้รับมอบหมาย

เมื่อคุณสมัครใช้บริการที่เกี่ยวข้องจากพันธมิตรบุคคลที่สามผ่านแอปพลิเคชัน Q Money เราจะให้ข้อมูลผู้ใช้ของคุณแก่บุคคลที่สามที่ได้รับอนุญาตจากคุณ

เราและบริษัทในเครือของเราอาจให้ข้อมูลส่วนบุคคลของคุณแก่บริษัทในเครือ คู่ค้า และผู้ให้บริการบุคคลที่สาม ผู้รับเหมา และตัวแทน (เช่น เพื่อให้บริการด้านการสื่อสารที่ส่งข้อความหรือการแจ้งเตือนในนามของเรา เพื่อให้ข้อมูลตำแหน่งถูกแบ่งปันกับแผนที่ ผู้ให้บริการ (ซึ่งอาจไม่อยู่ในเขตอำนาจศาลของคุณ) เพื่อให้เราสามารถให้บริการหรือปรับปรุงบริการของเราให้กับคุณได้

ในขณะที่ธุรกิจของเราพัฒนาอย่างต่อเนื่อง เราและบริษัทในเครือของเราอาจเข้าสู่การควบรวมกิจการ การเข้าซื้อกิจการ การโอนสินทรัพย์ หรือธุรกรรมที่คล้ายกัน และข้อมูลส่วนบุคคลของคุณอาจถูกถ่ายโอนโดยเป็นส่วนหนึ่งของธุรกรรมดังกล่าว

Q Money จะไม่ขายหรือให้เช่าข้อมูลผู้ใช้ใด ๆ ให้กับบุคคลที่สามเพื่อวัตถุประสงค์ใด ๆ เว้นแต่เราจะยอมรับการเปลี่ยนแปลงในนโยบายของเราและแจ้งให้คุณทราบถึงการเปลี่ยนแปลงใด ๆ

7. การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว

เนื่องจากการเปลี่ยนแปลงในกฎหมายและข้อบังคับ และเพื่อติดตามการพัฒนาใหม่ๆ และแนวโน้มที่เป็นไปได้บนอินเทอร์เน็ต เราอาจแก้ไขนโยบายนี้เป็นครั้งคราว ดังนั้น เราขอสงวนสิทธิ์ในการเปลี่ยนแปลงดังกล่าว ซึ่งจะส่งผลให้สิทธิ์ของคุณลดลงอย่างมากภายใต้นโยบายความเป็นส่วนตัวนี้ตามดุลยพินิจของเราแต่เพียงผู้เดียว ในกรณีนี้ หากคุณยังคงใช้บริการของเรา แสดงว่าคุณตกลงที่จะผูกพันตามนโยบายความเป็นส่วนตัวฉบับแก้ไข

หากมีการเปลี่ยนแปลงนโยบายความเป็นส่วนตัวของเรา เราจะส่งการแจ้งเตือนการเปลี่ยนแปลงผ่านทาง SMS หรือข้อความ ป๊อปอัพหน้าแอปพลิเคชัน

8.ติดต่อเรา

อีเมลติดต่อ: finance@gomatrixcompany.com

ที่อยู่: 88/422 Naradhiwas Rajanagarindra Road 10120 SATHORN

โทรศัพท์: +66 679537324

บริษัท: GOMATRIX COMPANY LIMITED

เว็บไซต์อย่างเป็นทางการ: www.gomatrixcompany.com`,
    };
  },
  created() {
    this.gotoTop();
  },
  methods: {
    gotoTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: white;
}

img {
  display: block;
}

.imgBox1,
.imgBox2,
.imgBox3,
.imgBox4,
.imgBox5,
.imgBox6,
.imgBox7 {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: "";
}

.img1Box,
.img2Box,
.img3Box,
.img4Box,
.img5Box,
.img6Box,
.img7Box {
  margin: 0;
  overflow: hidden;
  width: 100%;
  background-color: "";
}

.imgItem1,
.imgItem2,
.imgItem3,
.imgItem4,
.imgItem5,
.imgItem6,
.imgItem7 {
  width: 100%;
  margin: 0;
}

.img5Box {
  margin-top: 50px;
}
.textBox{
  color:#333333;
  white-space: pre-wrap;
}
</style>
