var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar min-w-1400"},[_c('div',{staticClass:"secondBox max-w-1400"},[_vm._m(0),_c('ul',{staticClass:"nav-links"},[_c('li',{staticClass:"Li1",class:{ 'active-link': _vm.isActive('/') }},[_c('router-link',{staticClass:"nav-link1",attrs:{"to":"/"}},[_c('span',{staticClass:"routeText1"},[_vm._v("ประวัติบริษัท")])])],1),_c('li',{staticClass:"dropdown Li2",class:{
        'active-link': _vm.$route.path === '/about'
      }},[_c('router-link',{staticClass:"nav-link2",attrs:{"to":"/about"}},[_c('span',{staticClass:"dropdown-toggle routeText2"},[_vm._v("ผลิตภัณฑ์ของเรา")])]),_c('ul',{ref:"dropdownMenu",staticClass:"dropdown-menu"},[_c('li',{class:{
            'active-link1': _vm.$route.path === '/introduce1',
          },staticStyle:{"color":"red"}},[_vm._m(1)])])],1),_c('li',{staticClass:"Li3",class:{ 'active-link': _vm.$route.path === '/question' }},[_c('router-link',{staticClass:"nav-link3",attrs:{"to":"/question"}},[_c('span',{staticClass:"routeText3"},[_vm._v("ศูนย์ช่วยเหลือ")])])],1),_c('li',{staticClass:"Li4",class:{ 'active-link': _vm.$route.path === '/protocol' }},[_vm._m(2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('span',{staticClass:"title"},[_vm._v("GOMATRIX COMPANY LIMITED")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link2",attrs:{"href":"http://qmoney.gomatrixcompany.com/","target":"_blank"}},[_c('span',{staticClass:"dropdown-toggle routeText6"},[_vm._v("ผลิตภัณฑ์สินเชื่อ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link3",attrs:{"href":"https://cltiw.chookiatcl.com/help/privacy_policy","target":"_blank"}},[_c('span',{staticClass:"routeText4"},[_vm._v("ข้อตกลงความเป็นส่วนตัว")])])
}]

export { render, staticRenderFns }